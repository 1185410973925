import { ChevronDownIcon } from '@heroicons/react/solid'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { ThemeProps } from '../theme/ThemeProps'
import { buildClasses, buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ReactNode, Ref, forwardRef, useId } from 'react'
import ReactSelect, { GroupBase, Props, SelectInstance, components } from 'react-select'
import SelectFieldOption from './SelectFieldOption'
import Tooltip, { TooltipRef } from '../tooltip/Tooltip'

export type SelectFieldSize = 'sm' | 'md' | 'xs'

export type SelectFieldProps<
    OptionType,
    Group extends GroupBase<OptionType> = GroupBase<OptionType>,
    IsMulti extends boolean = false
> = Omit<Props<OptionType, IsMulti, Group>, 'className' | 'classNamePrefix' | 'loadOptions'> &
    ThemeProps & {
        label?: string | ReactNode
        srOnlyLabel?: boolean
        subtitle?: string | ReactNode
        valid?: boolean
        size?: SelectFieldSize
        message?: string
        className?: string
        removePaddingTop?: boolean
        renderMessageInTooltip?: boolean
        tooltipRef?: Ref<TooltipRef>
        disablePortal?: boolean
        tooltipMessage?: string
        compact?: boolean
    }

export type SelectFieldRefType<
    OptionType,
    Group extends GroupBase<OptionType>,
    IsMulti extends boolean = false
> = SelectInstance<OptionType, IsMulti, Group>

const SelectField = <
    OptionType,
    Group extends GroupBase<OptionType> = GroupBase<OptionType>,
    IsMulti extends boolean = false
>(
    {
        id,
        label,
        srOnlyLabel,
        subtitle,
        size = 'md',
        color = 'primary',
        valid = true,
        className,
        message,
        placeholder = '',
        isSearchable = false,
        removePaddingTop,
        menuPosition = 'fixed',
        renderMessageInTooltip,
        tooltipRef,
        disablePortal,
        tooltipMessage,
        compact = false,
        ...props
    }: SelectFieldProps<OptionType, Group, IsMulti>,
    ref: Ref<SelectFieldRefType<OptionType, Group, IsMulti>>
) => {
    const randomId = useId()

    const renderLabel = () => {
        return (
            (label || placeholder) && (
                <label htmlFor={id || randomId} className={buildClasses({ 'sr-only': !label || srOnlyLabel })}>
                    {label || placeholder}
                    {subtitle && <small>{subtitle}</small>}
                </label>
            )
        )
    }

    const renderMessage = () => {
        return message && <span className='select-field-message'>{message}</span>
    }

    const containerClassName = buildClassesWithDefault(
        {
            invalid: !valid,
            [color]: true,
            [size]: true,
            compact,
            selector: true,
            '!pt-0': removePaddingTop
        },
        className
    )

    const testAttributes = {
        'data-test-element': 'select',
        'data-test-element-type': props.isMulti ? 'multi-select' : 'single-select',
        'data-test-name': props?.name,
        'data-test-selected-label': (props?.value as Record<string, unknown>)?.label,
        'data-test-selected-value': (props?.value as Record<string, unknown>)?.value,
        'data-test-is-disabled': props?.isDisabled
    }

    const renderInput = (additionalRef?: Ref<HTMLInputElement>, additionalProps: Record<string, unknown> = {}) => {
        return (
            <>
                <div {...additionalProps} {...testAttributes} ref={additionalRef}>
                    <ReactSelect
                        {...props}
                        id={id || randomId}
                        instanceId={id || randomId}
                        isSearchable={isSearchable}
                        className={buildClasses('selector-container', {
                            [size]: true,
                            compact
                        })}
                        menuPortalTarget={
                            typeof document !== 'undefined' && menuPosition === 'fixed' && !disablePortal
                                ? document.body
                                : undefined
                        }
                        classNamePrefix='selector'
                        placeholder={placeholder}
                        menuPosition={menuPosition}
                        openMenuOnFocus
                        openMenuOnClick
                        components={{
                            Option: SelectFieldOption,
                            ...(compact &&
                                ({
                                    DropdownIndicator: props => {
                                        return (
                                            <components.DropdownIndicator {...props}>
                                                <ChevronDownIcon className='absolute bottom-0 right-0 h-5 w-5' />
                                            </components.DropdownIndicator>
                                        )
                                    }
                                } as Partial<SelectComponents<OptionType, IsMulti, Group>>))
                        }}
                        ref={ref}
                    />
                </div>
                {!renderMessageInTooltip && renderMessage()}
            </>
        )
    }

    const renderTooltip = () => {
        return (
            <span className='flex items-center gap-1 pb-1'>
                <Tooltip placement='top' content={tooltipMessage} contentClassName='max-w-[theme(width.64)]'>
                    <InformationCircleIcon className='h-5 w-5 text-primary' />
                </Tooltip>
            </span>
        )
    }

    return (
        <div className={containerClassName}>
            <div className='flex gap-1'>
                {renderLabel()}
                {tooltipMessage?.length > 0 && renderTooltip()}
            </div>
            <div className='flex flex-col relative'>
                {renderMessageInTooltip ? (
                    <Tooltip
                        content={message}
                        backgroundColor={!valid ? 'danger.DEFAULT' : 'primary.DEFAULT'}
                        textColor='white'
                        ref={tooltipRef}
                        disabled={!renderMessageInTooltip || !message}
                        lazy={false}
                    >
                        {renderInput}
                    </Tooltip>
                ) : (
                    renderInput()
                )}
            </div>
        </div>
    )
}

export default forwardRef(SelectField)
